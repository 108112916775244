// import external dependencies
import 'jquery'; 

// Import everything from autoload
import './autoload/_bootstrap.js'
 
const swiper22 = new Swiper('.swiper', {
  speed: 400, 

  autoplay: {
    delay: 5000,
  },
  autoHeight: true, //enable auto height
  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
});


const swiper2 = new Swiper('.swiper-news', {
  speed: 400, 
  spaceBetween: 30,
  slidesPerView: 4,
  autoplay: {
   delay: 5000,
 },
  autoHeight: true, //enable auto height
  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  breakpoints: {
    0: { /* when window >=0px - webflow mobile landscape/portriat */
      slidesPerView: 1,
      spaceBetween: 20,
    },
    767: { /* when window >= 767px - webflow tablet */
      slidesPerView: 2,
      spaceBetween: 30,
    },
    988: { /* when window >= 988px - webflow desktop */
      slidesPerView: 4,
      spaceBetween: 40,
    }
  },
});




const swiper3 = new Swiper('.swiper-oceny', {
  speed: 400, 
  spaceBetween: 30,
  slidesPerView: 4,
  autoplay: {
   delay: 4000,
 },
  autoHeight: true, //enable auto height
  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  breakpoints: {
    0: { /* when window >=0px - webflow mobile landscape/portriat */
      slidesPerView: 1,
      spaceBetween: 20,
    },
    767: { /* when window >= 767px - webflow tablet */
      slidesPerView: 2,
      spaceBetween: 30,
    },
    988: { /* when window >= 988px - webflow desktop */
      slidesPerView: 4,
      spaceBetween: 40,
    }
  },
});


$(window).scroll(function() {    
  var scroll = $(window).scrollTop();

   //>=, not <=
  if (scroll >= 30) { 
      $("header.banner").addClass("fixed");
  } else {
    $("header.banner").removeClass("fixed");
  }
}); //missing );